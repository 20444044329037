<div class="feature-field">
    <label class="feature-label" [ngClass]="{ disabled: field.value == null }">
        {{ field.displayName }}
        <span *ngIf="field.required">*</span>
    </label>
    <div class="feature-data" *ngIf="!editMode">
        <ul class="coded choice-list">
            <ng-container *ngFor="let option of field.options">
                <li *ngIf="optionSelected[option.description]?.selected">
                    <i class="icon-24-dark-radio-selected"></i>
                    <label title="{{ option.description }}">{{ option.description }}</label>
                </li>
            </ng-container>
        </ul>
    </div>
    <div class="editable-control" *ngIf="editMode">
        <div class="coded choice">
            <gsp-dropdown
                id="{{ field.name }}-{{ field.id }}"
                [ngClass]="{ 'has-error': field?.hasError }"
                [options]="field.options"
                [idField]="'code'"
                [textField]="'description'"
                [(ngModel)]="selectedValue"
                (ngModelChange)="toggleOption($event)"
                [includeNoSelection]="!field.required"
                [placeholder]="field.required ? 'TC.Common.MustHaveCodedChoice' : 'TC.Common.SelectOption'"
                [multiple]="false"
                [minimumSearchLimit]="5"
            ></gsp-dropdown>
        </div>
    </div>
</div>
