<div class="feature-field">
    <label class="feature-label" [ngClass]="{ disabled: field.value == null }">
        {{ field.displayName }}
        <span *ngIf="field.required">*</span>
    </label>

    <div class="feature-data" *ngIf="!editMode">
        <ul class="choice-list">
            <ng-container *ngFor="let option of fieldOptionsWithoutOthers()">
                <li *ngIf="isSelected(option)">
                    <i class="icon-24-dark-check-selected" *ngIf="field.allowMultiSelect === true"></i>
                    <i class="icon-24-dark-radio-selected" *ngIf="field.allowMultiSelect === false"></i>
                    <label title="{{ option }}">{{ option }}</label>
                </li>
            </ng-container>
            <li *ngIf="isOtherOptionSelected && !!otherOption.length">
                <i class="icon-24-dark-check-selected" *ngIf="field.allowMultiSelect === true"></i>
                <i class="icon-24-dark-radio-selected" *ngIf="field.allowMultiSelect === false"></i>
                <label title="{{ otherOption }}">{{ otherOption }}</label>
            </li>
        </ul>
    </div>

    <div class="editable-control" *ngIf="editMode">
        <div class="choice">
            <gsp-dropdown
                [ngClass]="{ 'has-error': field?.hasError }"
                id="{{ field.name }}-{{ field.id }}"
                [options]="field.options"
                [(ngModel)]="selectedOptions"
                (ngModelChange)="selectionChange($event)"
                [includeNoSelection]="!field.required && !field.allowMultiSelect"
                [multiPlaceholder]="field.required ? 'TC.Common.MustHaveChoice' : 'TC.Common.ClickOption'"
                [multiple]="field.allowMultiSelect"
                [minimumSearchLimit]="5"
            ></gsp-dropdown>
        </div>

        <div class="choice other-textbox" *ngIf="showOtherForm && isOtherOptionSelected">
            <label class="feature-label">{{ 'TCW_OTHER' | translate }}:</label>
            <input class="gsp-form-input" [(ngModel)]="otherOption" maxlength="{{ field.maxLength }}" />
        </div>
    </div>
</div>
